.projects
  display: flex
  background: white

  @include mq(medsmall)
    flex-direction: column
    width: 100%

  h3
    margin-top: 6rem

  .comp-view
    opacity: 0.1
    margin:
      top: 3.5rem
      bottom: 6rem
      left: -4%
      right: -4%
    transition: all 350ms ease-in-out

    &.at-scroll
      margin:
        left: -5%
        right: -5%
      opacity: 1

  .go-link
    background-color: rgba(black, 0.3)
