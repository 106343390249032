.dropnav
  display: none
  position: fixed
  top: 0
  left: 0
  right: 0
  background: $color-primary
  z-index: 10
  padding: 1.5rem 0
  @extend %clearfix

  h2
    float: left
    color: white
    font-size: 1rem
    margin: 0 0 0 5%
    padding: 0px

  .cta
    float: right
    margin-right: 5%

    span
      font-size: 1rem

    &:hover span
      background-color: white
      color: $color-primary

    span.icon
      background-color: white

    i
      color: black

    &:hover i
      color: $color-primary
