@import variables
@import functions
@import helpers
@import mixins
@import type


///////////////////////////////////////////////
// GENERAL SITE STUFF
///////////////////////////////////////////////

body
  background:
    color: #888
  margin: 0px
  line-height: $base__line / $base__font-size
  font-family: $font-family--primary
  font-size: $base__font-size
  font-weight: 300
  letter-spacing: 0.02em
  color: black

  @include mq(small)
    font-size: 16px
    line-height: 1.5

img
  max-width: 100%

a
  color: $color-primary
  border-color: $color-primary

.cta
  display: inline-block
  position: relative
  text-decoration: none
  opacity: 0
  transition: all 500ms ease-in-out

.cta.appear
  opacity: 1

.flexcontain
  display: flex

  span
    color: white
    background-color: black
    font-size: 1.2rem
    padding: 0 0.5rem
    transition: all 150ms ease-in-out

  &:hover span
    background-color: $color-primary

  span.icon
    background-color: $color-primary
    color: white
    font-size: 1.2rem
    padding: 0 0.6rem
    transition: all 150ms ease-in-out

  i
    color: white
    transition: all 150ms ease-in-out

  &:hover i
    color: black

.container
  width: 70%
  max-width: 1400px
  margin:
    left: auto
    right: auto

.go-link
  display: inline-block
  background: rgba(white, 0.3)
  color: white
  padding: 0 0.5rem
  text-transform: lowercase
  text-decoration: none

  &:hover
    background: rgba(white, 0.5)
    color: black


///////////////////////////////////////////////
// IMPORTS
///////////////////////////////////////////////

@import 'dropnav'
@import 'header'
@import 'feature'
@import 'skills'
@import 'rockem'
@import 'goodshep'
@import 'projects'
@import 'westbury'
@import 'fearless'
@import 'footer'
