.footer
  background: #DDD

  .container
    padding: 6rem 0
    width: 90%
    max-width: 1200px
    display: flex

    @include mq(small)
      width: 80%
      flex-direction: column

  .left-side
    width: 45%
    margin-right: 10%

    @include mq(small)
      width: 100%

  .right-side
    @include mq(small)
      h2
        display: none

  h2
    color: black


  p
    a
      display: inline-block
      background: black
      color: white
      text-decoration: none
      padding:
        left: 0.2em
        right: 0.2em

      &:hover
        background: $color-primary

  .contact-list
    padding: 0
    margin: 0

    li
      list-style: none

    a
      color: black

      &:hover
        color: $color-primary
