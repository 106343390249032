// ==========================================================================
// Helpers
// ==========================================================================

// Clearfix

%clearfix
  &:after
    content: ""
    display: table
    clear: both


// Screen reader text

.srt
  border: 0
  clip: rect(0 0 0 0)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  width: 1px

// Center alignment

%center-align
  text-align: center

// Center elements

.centered
  float: none
  margin-left: auto
  margin-right: auto
