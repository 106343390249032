h1, h2, h3
  font-family: $font-family--secondary
  font-weight: 400
  line-height: 1.5
  letter-spacing: 0.03em
// text-transform: uppercase

h1
  font-size: 2.5rem

h2
  font-size: 2rem
  line-height: 1.5
  margin:
    top: 1.5rem
    bottom: 1.5rem
  color: $color-primary

h3
  font-size: 1.5rem
  line-height: 1
  margin:
    top: 1.5rem
    bottom: 1.5rem
  color: $color-primary

p
  margin:
    bottom: 1.5rem
    top: 1.5rem
