.project-westbury
  width: 50%
  background:
    color: white
  @extend %clearfix

  @include mq(small)
    width: 100%

  .inside
    float: right
    margin-right: 10%
    margin-left: 10%
    width: 80%
    max-width: 560px

    @include mq(small)
      float: none
      margin: 0 10%
      width: auto
      max-width: 100%
