.skills
  background-color: white

  .container
    padding:
      top: 6rem
      bottom: 6rem
    @extend %clearfix

    @include mq(small)
      width: 80%

.bio-pic
  background-color: #ddd
  background-image: url(../../static/img/donut.jpg)
  background-size: cover
  border-radius: $br--round
  height: 13.5rem
  width: 13.5rem
  float: right
  margin: 3em 0 1em 2em

  @include mq(small)
    float: none
    margin: 0 auto


.skills-container
  background-color: rgba(black, 0.035)

  h3
    margin-left: 0.5%

.skill-list
  margin: 0 0 1.5rem 0
  padding: 0
  @extend %clearfix

  li
    display: block
    float: left
    width: 32.3333%
    padding: .5em 0
    box-shadow: 0 2px 0 0 #EEE
    text-align: center
    background-color: white
    margin: 0 0.5% 0.5rem 0.5%

    @include mq(small)
      width: 49%

    &:last-child
      @include mq(small)
        display: none


.links
  margin: 1.5rem 0 1.5rem 0
  padding: 0

  @include mq(small)
    text-align: center

  li
    display: inline-block
    font-size: 2rem
    margin-right: 2%

  a
    color: black

    &:hover
      color: $color-primary
