header
  background:
    image: url(../../static/img/header.jpg)
    size: cover
    attachment: fixed
    repeat: no-repeat
  padding: 8em 0
  position: relative

  p
    margin-top: 1rem
    margin-bottom: 1rem

  .container
    max-width: 850px
    padding-bottom: .2rem

  .headholder
    position: absolute

  .info
    font-size: 2.5rem
    line-height: 1.8
    position: relative
    z-index: 3
    font-family: $font-family--secondary
    font-weight: normal

    span
      background-color: $color-primary
      color: white
      padding:
        left: 0.3em
        right: 0.35em
        top: 0.1em
      box-decoration-break: clone

  strong
    background-color: rgba(black, 0.3)
    font-weight: normal
    padding:
      left: 0.1em
      right: 0.1em
      top: 0.1em

  .shadow
    font-size: 2.5rem
    line-height: 1.8
    margin-top: 0.5em
    margin-bottom: 0.5em
    position: absolute
    z-index: 2
    opacity: 0
    top: 3.3em
    margin-left: .2em
    width: 70%
    max-width: 850px
    filter: blur(10px)
    transition: opacity 800ms ease-in-out
    font-family: $font-family--secondary
    font-weight: normal

    span
      color: black
      background-color: black
      padding:
        left: 0.3em
        right: 0.35em
        top: 0.1em
      box-decoration-break: clone

  p.scrolled
    opacity: 0.2

  .chill
    position: relative
    z-index: 1
    top: -3.5em
    opacity: 0
    transition: opacity 500ms ease-in-out
    font-family: $font-family--secondary

    span
      background: #888
      padding: 5px 15px
      color: #222

  .chill.scrolled
    opacity: 1

  .cta
    top: -2.5rem

.typed-cursor
  display: none

.photo-cred
  position: absolute
  bottom: 1rem
  right: 1rem
  color: white
  width: 80%

  i
    float: right
    opacity: 0.8
    font-size: 1.5rem
    cursor: pointer

  .tooltip
    float: right
    position: relative
    margin-right: 1rem
    background: black
    padding: 0.3em 0.85em 0.35em 0.8em
    margin-top: -1rem
    font-size: 0.8rem
    color: #CCC
    opacity: 0
    transition: opacity 200ms ease-in-out
    border-radius: 4px

    span
      display: block
      font-size: 1rem
      font-weight: bold
      color: white

  .tooltip.hovered
    opacity: 1
