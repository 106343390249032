// ==========================================================================
// Variables
// ==========================================================================

// Font Stacks

// $font-url--google        : 'https://fonts.googleapis.com/css?family=Lora|Istok+Web'
$font-url--google        : 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700'
$font-family--primary    : 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif
// $font-family--secondary  : 'Istok Web', 'Helvetica Neue', Helvetica, Arial, sans-serif
$font-family--secondary    : 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif

// Font Weights

$font-weight--thin       : 100
$font-weight--light      : 300
$font-weight--medium     : 400
$font-weight--bold       : 700
$font-weight--ultra-bold : 900

// Descriptive Base Colors

$white    : #fff
// $orangered  : #f44336
$orangered : rgba(255, 80, 28, 1)
$grey     : #797e83
$lightgrey: #909090


// Color Usage

$color-primary   : $orangered
$color-shadow    : rgba(black, .2)

// Text

$base__font-size : 16px
$base__line      : 24px
$letter-space    : 1px

// Border radius

$br--default    : .4em
$br--round      : 50%

// Transitions

$trns-duration  : .3s

// Path to Assets

$path           : "../img"

// Media Query Breakpoints

$breakpoint-sm  : 1px
$breakpoint-ms	: 778px
$breakpoint-md  : 850px
$breakpoint-lg  : 1100px